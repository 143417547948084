

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@import url('https://assets.calendly.com/assets/external/widget.css');
@media only screen and (max-width: 576px) {
  .email_text {
    font-size: 7px;
  }
  .c_n {
    font-size: 10px;
  }
  .img {
    width: 10px;
  }
  .pl_m {
    padding-left: 11px;
  }
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.contactCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px;
  /* justify-content: center; */
}

.contactCard {
  flex: 1 1 200px;
  max-width: calc(25% - 16px);
  min-width: calc(20%);
}

.blurred-container {
  position: relative;
}

.blurred-content {
  /* Add your styling for the content here */
  /* For example: */
  /* background-color: white; */
  /* Other styles */
  /* border: 2px solid #be87f4; */
  /* Apply the blur effect */
  pointer-events: none;
  filter: blur(4px);
}

.overlay-button {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add other styling for the button */
}
.overlay-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add other styling for the button */
}

@media (max-width: 1200px) {
  .contactCard {
      max-width: calc(33.333% - 16px); /* 3 cards per row */
  }
}

@media (max-width: 768px) {
  .contactCard {
      max-width: calc(50% - 16px); /* 2 cards per row */
  }
}

@media (max-width: 480px) {
  .contactCard {
      max-width: 100%; /* 1 card per row */
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

option {
  color: rgb(161, 160, 160) !important;
}
button:disabled {
  background-color: #ccc; /* Gray background for disabled state */
  cursor: not-allowed;    /* Change cursor to indicate non-interactivity */
  opacity: 0.6;      
  color: #2B2B2B;    /* Optional: reduce opacity for a "disabled" look */
}
font-face {
  font-family: Poppins-Md;
  src: url("/public/Fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins-bold;
  src: url("/public/Fonts/Poppins-ExtraBold.ttf");
}

.poppins_Medium {
  font-family: Poppins-Md;
}
.poppins_bold_extra {
  font-family: Poppins-bold;
}
.clr{
  color:#2B2B2B !important;
}
.scd_header{
height: 80px;
}
.logo_2{
  height: 30px !important;
}
.fix_width{
  width: 140px !important; 
}
.text_xs{
  font-size: 12px;
  padding-top: 15px !important;
}
.text_xs1{
  font-size: 12px;
  padding-top: 10px !important;
}
.bg_clr{
  background-color: #F1EEF4;
}
.bg_sc{
  background-color: #1F0048;
}

